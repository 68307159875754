import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
             I am <span className="purple">Muhammad Shahmeer Khan </span>
            based out of <span className="purple"> Karachi, Pakistan.</span>
            <br />
            I am currently employed as a software engineer at KAISPE.
            <br />
            I have completed my BE (Engineering) in Software Engineer at NED university
            Karachi.
            <br />
            My passion and expertise are in ERP and Business systems
            <br/>
            Like Oracle NetSuite, Fusion and MS Dynamics 365
          </p>
          
          

          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
